import { createStore } from 'vuex';
import router from '@/router';

const LOCAL_AUTH_KEY = 'ltomsauth';

export default createStore({
  state: {
    user: null // Initialize as null, we'll populate it after checking the token
  },
  mutations: {
    setUser(state, user) {
      state.user = user; // Store the full user object in state
      // Check if user and user_auth_token exist before storing
      if (user && user.user_auth_token) {
        localStorage.setItem(LOCAL_AUTH_KEY, user.user_auth_token); // Store only the token as a string
      } else {
        console.warn('Attempted to set user without valid auth token');
      }
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem(LOCAL_AUTH_KEY);
    }
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
    },
    logout({ commit }) {
      commit('logout');
      router.push('/login');
    },
    async checkLoginStatus({ commit, state }) {
      // Check if the user is already logged in via Vuex state
      if (state.user) {
        return true;
      }
    
      // If not, check for token in localStorage
      const user_auth_token = localStorage.getItem(LOCAL_AUTH_KEY);
      
      // Add additional validation for the token
      if (!user_auth_token || user_auth_token === 'undefined' || user_auth_token === 'null') {
        localStorage.removeItem(LOCAL_AUTH_KEY); // Clean up invalid token
        return false;
      }
    
      try {
        const payload = { user_auth_token };
        const response = await fetch(process.env.VUE_APP_API_URL + '/app/controllers/session_controller.php?controller=session&method=check&params=' + JSON.stringify(payload));
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const result = await response.json();
        
        if (result.status === 'success' && result.user) {
          commit('setUser', result.user);
          return true;
        } else {
          localStorage.removeItem(LOCAL_AUTH_KEY); // Clean up invalid token
          console.log(result.message);
          return false;
        }
      } catch (error) {
        localStorage.removeItem(LOCAL_AUTH_KEY); // Clean up on error
        console.error("Failed to check login - error: " + error.message);
        return false;
      }
    }
  },
  getters: {
    isLoggedIn(state) {
      return !!state.user;
    },
    user: state => state.user // This will now return the full user object
  }
});