<template>
  <nav v-if="isLoggedIn">
    <router-link to="/" class="router-link dashboard">
      <DashboardIcon />
    </router-link>
    <router-link to="/ontology" class="router-link ontology">Ontology</router-link>
    <router-link to="/admin" class="router-link admin">Admin</router-link>
    <template v-if="isLoggedIn">
      <div class="nav-menu user">
        <div><i class="las la-bars"></i></div>
        <ul>
          <li>
            <div class="nav-button logout" @click="logout">Logout</div>
          </li>
        </ul>
      </div>
    </template>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  methods: {
    ...mapActions(['logout'])
  }
};
</script>

<style lang="scss">
  nav {
    display: flex;
    justify-content: left;
    height: $navbar-height;
    background: $app-background-color;
    
    a, .nav-button, .nav-menu {
      display: inline-block;
      font-weight: bold;
      height: calc($navbar-height - $navbar-active-highlight-height);
      line-height: calc($navbar-height - $navbar-active-highlight-height);
      color: $app-text-color;
      text-decoration: none;
      font-weight: 500;
      padding: 0 10px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #d7d7d7;
      }

      &.router-link {
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }

      &.router-link-exact-active {
        color: $ltoms-blue;
        border-bottom: 2px solid $ltoms-blue;
      }

      &.router-link.login, &.logout, &.user {
        margin-left: auto;
      }

      &.user {
        font-weight: 500;
        font-size: 16px;
      }

      &.nav-button {
        font-weight: 400 !important;
      }
    }

    .nav-menu {
      position: relative;
      overflow: hidden;
      padding: 0px;
      height: $navbar-height;
      line-height: $navbar-height;
      z-index: 10;

      ul {
        height: 0px;
        margin: 0;
        padding: 0;
        background: #d0d0d0;
        list-style-type: none;
        padding: 0px;

        li {
          line-height: normal;

          .nav-button {
            font-size: 14px !important;
            font-weight: 700;
          }

          &:hover {
            &, .nav-button {
              background-color: #aaaaaa;
            }
          }
        }
      }

      &:hover {
        overflow: visible;
        
        ul {
          height: auto !important;
        }
      }
    }
  }
</style>