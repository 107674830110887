<template>
  <div view="ontology">
    <div class="view-panel full">
      <div class="view-table">
        <div class="view-table-title">
          <div>Tables
            <span class="info-icon" tool-tip>
              <div class="tool-tip right">
                <div class="tool-tip-content">
                  These are your custom Ontology data tables,<br>
                  here you can view their columns and data.
                </div>
              </div>
              <i class="las la-info-circle"></i>
            </span>
          </div>
          <div class="flex-0">
            <router-link to="/create/table">
              <button class="icon transparent ml-auto" @click="openCreateTableModal"><i class="las la-plus"></i></button>
            </router-link>
          </div>
        </div>
        <div class="view-table-content">
          <div v-for="table in tables" @click="loadDataPreview(table.table_id);selectTable(table.table_slug);" :table-slug="table.table_slug" class="view-table-row">
            <div class="table-data">
              <div class="table_name">
                <i>{{ table.table_slug }}</i>
              </div>
              <div class="rows">
                {{ table.rows }} rows
              </div>
              <div class="columns" tool-tip>
                |&nbsp; {{ table.columns + 1 }} <u>column{{ table.columns == 1 ? '': 's' }}</u>
                <div class="tool-tip right">
                  <div class="tool-tip-content">
                    <ul>
                      <li>{{ table.table_slug }}_id <small>(int)</small></li>
                      <li v-for="field in table.table_fields">
                        {{ field.table_field_slug }} <small>({{ field.table_field_type }})</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="view-table-menu" :table-slug="table.table_slug">
              <ul>
                <li class="menu-option" @click="loadDataImport(table.table_slug, table.table_name)">
                  <div class="menu-option-content">
                    <span id="CSVImportIcon"></span>
                  </div>
                </li>
                <li class="menu-option" @click="truncateTableData(table.table_slug)">
                  <div class="menu-option-content">
                    <span id="TruncateDataIcon"></span>
                  </div>
                </li>
                <li class="menu-option" @click="dropTable(table.table_slug)">
                  <div class="menu-option-content">
                    <span id="DropTableIcon"></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tables: []
    }
  },
  methods: {
    openCreateTableModal(){
      this.$emit('open-modal', 'create-table');
    },
    loadDataPreview(table_id){
      this.$emit('load-data-preview', table_id);
    },
    selectTable(table_slug){
      let currentSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug].selected");

      if(currentSelection) {
        currentSelection.classList.remove("selected");
        currentSelection.querySelector(".view-table-menu").style.display = "none";
      }

      let newSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug="+table_slug+"]");

      if(newSelection) {
        newSelection.classList.add("selected");
        newSelection.querySelector(".view-table-menu").style.display = "flex";
      }
    },
    deselectTable(){
      
    },
    loadDataImport(table_slug, table_name) {
      this.$emit('open-modal', 'data-import', { table_slug, table_name });
    },
    async truncateTableData(table_slug){
      if(!confirm("Are you sure you want to TRUNCATE data for "+table_slug+"? This action is irreversible and the data will be unrecoverable.")){
        return;
      }

      try {
        const payload = {
          table_slug: table_slug,
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=truncate&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.dataPreview = result.data;

          try {
            const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
              method: 'GET'
            });

            const result = await response.json();

            if (result.status === 'success') {
              this.tables = result.tables;
              deselectTable();
            } else {
              alert(result.message);
            }
          } catch (error) {
            console.log("failed to load tables on dashboard - error: "+error.message);
          }
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed to load data preview - error: "+error.message);
      }
    },
    async dropTable(table_slug){
      if(!confirm("Are you sure you want to DROP both the table and data for "+table_slug+"? This action is irreversible, the data will be unrecoverable, and you will lose all table fields and structure.")){
        return;
      }

      try {
        const payload = {
          table_slug: table_slug,
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=drop&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.dataPreview = result.data;

          try {
            const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
              method: 'GET'
            });

            const result = await response.json();

            if (result.status === 'success') {
              this.tables = result.tables;
              deselectTable();
              this.$emit('clear-data-preview');
            } else {
              alert(result.message);
            }
          } catch (error) {
            console.log("failed to load tables on dashboard - error: "+error.message);
          }
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed to load data preview - error: "+error.message);
      }
    }
  },
  async mounted() {
    try {
      const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php?controller=table&method=read&params=[]', {
        method: 'GET'
      });

      const result = await response.json();

      if (result.status === 'success') {
        this.tables = result.tables;
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.log("failed to load tables on dashboard - error: "+error.message);
    }
  },
}

function deselectTable(){
  // Deselect current selected table
  let currentSelection = document.querySelector("[view='ontology'] .view-table-row[table-slug].selected");

  if(currentSelection) {
    currentSelection.classList.remove("selected");
    currentSelection.querySelector(".view-table-menu").style.display = "none";
  }
}

</script>

<style lang="scss">
  [view="ontology"] {
    position: relative;

    .view-table-row {
      position: relative;

      &[table-slug].selected {
        background: linear-gradient(194deg, #1393ef, #1275bb) !important;
        color: #fefefe !important;
      }

      .view-table-menu {
        position: absolute;
        display: none;
        top: 2px;
        left: calc(100% + 15px);
        background: darken($app-view-form-color, 10%);
        color: $app-text-color;
        font-size: 12px;
        min-width: 80px;
        border-radius: 3px;

        &::after {
          content: '';
          position: absolute;
          display: block;
          top: 5px;
          width: 0px;
          height: 0px;
          border: 8px solid transparent;
          border-right-color: darken($app-view-form-color, 10%);
          right: calc(100% + 0px);
        }

        ul {
          @include flex-column;
          list-style-type: none;
          margin: 0;
          padding: 0;
          overflow: hidden;
          border-radius: 3px;

          li {
            @include flex-row;

            &.menu-option {
              padding: 5px 10px;
              
              &:hover {
                background: darken($app-view-form-color, 15%);
                cursor: pointer;
              }

              .menu-option-content {
                display: flex;
                justify-content: left;
                align-items: left;

                #CSVImportIcon {
                  &::before {
                    content: 'Import CSV';
                    display: block;
                    font-family: Verdana, sans-serif;
                    font-size: 0.8em;
                    color: #04a504;
                  }
                }

                #TruncateDataIcon {
                  &::before {
                    content: 'TRUNCATE';
                    display: block;
                    font-weight: 800;
                    font-family: Verdana, sans-serif;
                    font-size: 0.8em;
                    color: #fe6500;
                  }
                }

                #DropTableIcon {
                  &::before {
                    content: 'DROP';
                    display: block;
                    font-weight: 800;
                    font-family: Verdana, sans-serif;
                    font-size: 0.8em;
                    color: #a50404;
                  }
                }
              }
            }
          }
        }
      }
    }

    .table-data {
      @include flex-row;

      .table_name {
        flex: 1;
      }

      .columns, .rows {
        position: relative;
        margin-right: 5px;
        font-size: 12px;
        line-height: 18px;
      }

      .columns {
        cursor: help;
      }
    }
  }
</style>