<template>
  <div view="login">
    <div class="view-form login">
      <div class="form-title ltoms-signage">
        <div id="ltoms-signage">
          <span class="blue">LTOMS </span>
          <span>Software </span>
          <i>by Xtec</i>
        </div>
      </div>
      <div class="form-body">
        <input v-model="username" placeholder="Username" />
        <input type="password" v-model="password" placeholder="Password" />
        <button @click="authenticate">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['login']),
    async authenticate() {
      try {
        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/session_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=session&method=start&params={"username":"${this.username}","password":"${this.password}"}`
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.login(result.user);
          this.$router.push('/ontology');
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed login - error: "+error.message);
      }
    },
    async checkLoginStatus() {
      if (this.isLoggedIn) {
        return true;
      }

      const user_auth_token = localStorage.getItem('ltomsauth');
      if (user_auth_token) {
        try {
          const payload = { user_auth_token };
          const response = await fetch(process.env.VUE_APP_API_URL + '/app/controllers/session_controller.php?controller=session&method=check&params=' + JSON.stringify(payload));
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          if (result.status === 'success') {
            this.login(result.user); // Store user in Vuex
            return true;
          } else {
            console.log(result.message);
            return false;
          }
        } catch (error) {
          console.error("Failed to check login - error: " + error.message);
          return false;
        }
      }
      return false;
    }
  },
  async created() {
    const loggedIn = await this.checkLoginStatus();
    if (loggedIn) {
      this.$router.push('/ontology'); // If already logged in, redirect to the dashboard or home
    }
  }
};
</script>

<style lang="scss" scoped>
.view-form.login {
  min-width: 300px;
  margin-top: 30px !important;

  .form-body {
    input, button {
      margin: auto;
      margin-bottom: 15px;
    }
  }
}


#ltoms-signage {
  font-size: 12px;
  padding: 0 15px;

  span {
    font-weight: 400;
    font-size: 24px;

    &.blue {
      color: $ltoms-blue;
      font-weight: 800;
    }
  }

  i {
    display: block;
    opacity: 0.5;
  }
}
</style>
