<template>
  <div view="admin">
    <div class="view-panel full">

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tables: []
    }
  },
  async mounted() {
    
  },
}
</script>

<style lang="scss">
  [view="view-tables"] {
    position: relative;
  }
</style>