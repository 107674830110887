<template>
  <div id="dashboardIcon">
    <div class="row">
      <div class="cell"></div>
      <div class="cell"></div>
    </div>
    <div class="row">
      <div class="cell"></div>
      <div class="cell"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #dashboardIcon {
    display: flex;
    flex-flow: column nowrap;
    margin-top: 8px;

    .row {
      display: flex;
      flex-flow: row nowrap;
      flex: 1;

      .cell {
        display: block;
        flex: 1;
        width: 8px;
        height: 8px;
        margin: 1px;
        border-radius: 2px;
        background: darken($app-background-color, 25%);
        filter: invert(100%);
      }
    }
  }
</style>