<template>
  <Navbar />
  <div id="view">
    <router-view @open-modal="openModal" @load-data-preview="loadDataPreview" @clear-data-preview="clearDataPreview"/>
  </div>
  <DataPreview v-if="this.$route.path == '/ontology' || this.$route.path == '/'" :data="dataPreview" />
  <Modal v-if="showModal" :visible="showModal" @close-modal="closeModal">
    <component :is="modalComponent" :modal-data="modalData" @close-modal="closeModal"></component>
  </Modal>
</template>

<script>
import Modal from './components/Modal.vue';
import CreateTable from './components/CreateTable.vue';
import DataImport from './components/DataImport.vue';

export default {
  components: {
    Modal,
    CreateTable,
    DataImport
  },
  data() {
    return {
      showModal: false,
      modalComponent: null, // Dynamically rendered modal component
      modalData: {},
      dataPreview: {
        columns: [],
        rows: []
      }
    };
  },
  methods: {
    openModal(component, modalData={}) {
      this.modalComponent = component;
      this.modalData = modalData;
      this.showModal = true;

      // Blur the #view and $nav elements
      document.querySelector("#view").style.filter = "blur(5px)";
      document.querySelector("nav").style.filter = "blur(5px)";
    },
    closeModal() {
      this.showModal = false;
      this.$router.back();

      // Unblur the #view and $nav elements
      document.querySelector("#view").style.filter = "none";
      document.querySelector("nav").style.filter = "none";
    },
    async loadDataPreview(table_id) {
      try {
        const request = '/app/controllers/record_controller.php?controller=record&method=read';
        const params = '&params={"table_id":'+table_id+'}';
        
        const response = await fetch(process.env.VUE_APP_API_URL+request+params, {
          method: 'GET'
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.dataPreview = result.data;
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed to load data preview - error: "+error.message);
      }
    },
    clearDataPreview() {
      this.dataPreview = {
        columns: [],
        rows: []
      };
    }
  },
  mounted() {
    document.querySelector("title").innerHTML = "LTOMS Software (v"+process.env.VUE_APP_VERSION+")";
  }
};
</script>

<style lang="scss">


body {
  margin: 0;

  #app {
    @include flex-column;

    position: relative;
    background: $app-background-color;
    height: 100vh;

    &, #view {
      color: $app-text-color;
    }

    &, #view, #view button {
      font-family: "Red Hat Display", Helvetica, Arial, sans-serif;
      font-optical-sizing: auto;
      text-align: center;
    }

    hr {
      margin: 15px 0 15px 0;
      height: 1px;
      background: rgb(199, 199, 199);
      border: none;
    }

    #view {
      flex: 1;
    }

    #dataPreview {
      @include flex-row;

      height: 300px;
    }

    button {
      $border-color: darken($ltoms-blue, 5%);
      background: $ltoms-blue;
      color: #efefef;
      border: 1px solid desaturate($border-color, 25%);
      padding: 4px 8px;
      font-weight: 500;
      cursor: pointer;
      font-size: 14px;

      &.hollow {
        color: $ltoms-blue-hollow-text !important;
        background: $ltoms-blue-hollow !important;

        &:hover {
          color: white !important;
          background: $ltoms-blue-hollow-text !important;
        }

        i {
          font-size: 18px;
        }

        &.red-hollow {
          color: $ltoms-red-hollow-text !important;
          background: $ltoms-red-hollow !important;
          border: 1px solid desaturate($ltoms-red-hollow, 25%) !important;

          &:hover {
            color: white !important;
            background: $ltoms-red-hollow-text !important;
          }
        }

        &.green-hollow {
          color: $ltoms-green-hollow-text !important;
          background: $ltoms-green-hollow !important;
          border: 1px solid desaturate($ltoms-green-hollow, 25%) !important;

          &:hover {
            color: white !important;
            background: $ltoms-green-hollow-text !important;
          }
        }
      }

      &.cell {
        margin-left: auto;
      }

      i {
        font-size: 17px;
        line-height: 14px;
      }

      &:not(.icon) {
        i {
          position: relative !important;
          top: 2px;
          left: 2px;
        }
      }

      &:hover {
        background: darken($ltoms-blue, 10%);
      }

      &.icon {
        padding: 2px 2px 1px 3px !important;
        border: none !important;
        border-radius: 3px;

        &.red {
          background: rgb(163, 0, 0) !important;
        }

        i {
          font-size: 15px !important;
        }

        &.transparent {
          background: transparent !important;
          border-color: transparent !important;
          color: $app-text-color;
        }

        &.ml-auto {
          margin-left: auto;
        }

        &.mr-auto {
          margin-right: auto;
        }
      }
    }

    [view] {
      $view-padding: 15px;
      background: $app-view-color;
      height: calc(100% - ($view-padding*2));
      padding: $view-padding;

      &[view-modal] {
        padding: 0 !important;
        border-radius: 3px;
      }

      &[view=login] {
        height: calc(100vh - ($navbar-height - 10px));
      }

      .view-form, .view-panel {
        display: inline-block;
        min-height: 100px;
        margin: 0 auto;
        background: $app-view-form-color;
        border-radius: 3px;
        border: 1px solid darken($app-view-form-color, 25%);
        box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);

        p.tip, .form-title p {
          text-align: left;
          font-size: 14px;
          opacity: 0.75;
          max-width: 600px;
        }

        p.tip {
          margin: 0 0 15px 0;
        }

        .form-title {
          @include flex-row;

          padding: 15px 0 0 0;

          p,h1,h2,h3,h4,h5,h6 {
            margin: 0;
            padding: 0px 15px;
          }

          h1,h2,h3,h4,h5,h6 {
            font-weight: 500;
            text-align: left;
            font-size: 24px;
          }

          &.ltoms-signage {
            display: block;
            background: #efefef;
            padding-bottom: 15px;
            text-align: center;
            width: 100%;
          }
        }

        .form-body {
          @include flex-column;
          padding: 15px;

          .form-row {
            @include flex-row;
          }

          .form-field {
            @include flex-column;
            justify-content: left;
            align-items: left;
            flex: 0 !important;
            justify-content: center;

            &:not(:first-of-type) {
              margin-left: 10px;
            }

            &.checkbox {
              flex-flow: row nowrap !important;
              align-items: center !important;
              flex: 1 !important;
            }
          }

          .form-column, .form-wrap-column {
            @include flex-column;
          }

          .form-wrap-row {
            @include flex-row;
          }

          label {
            display: block;
            font-family: sans-serif;
            font-size: 12px;
            text-align: left;
            margin-bottom: 3px;
          }

          input, select {
            padding: 4px 6px;
            font-size: 14px;
            border-radius: 0px;
            background: rgb(245, 245, 245);
            border: 1px solid darken($app-view-form-color, 25%);

            &::placeholder {
              font-size: 12px;
            }

            &[readonly] {
              opacity: 0.75;
              cursor: not-allowed;
            }
          }

          input[type=checkbox] {
            display: inline-block;
            margin: 0;
          }
          
          label.checkbox-label {
            margin-left: 5px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0;
            line-height: 10px;
          }

          select {
            font-size: 12px;

            &, option {
              cursor: pointer;
            }
          }

          input, button {
            display: block;
          }

          button {
            $border-color: lighten($ltoms-blue, 40%);
            background: $ltoms-blue;
            color: #efefef;
            border: 1px solid desaturate($border-color, 25%);
            padding: 4px 12px;
            font-weight: 500;
            cursor: pointer;
            font-size: 14px;

            &:hover {
              background: darken($ltoms-blue, 25%);
              color: darken(#efefef, 25%);
            }
          }
        }

        &.login {
          border: 1px solid rgb(82 143 207 / 39%);
        }
      }

      .view-panel.full {
        display: block !important;
        width: 100% !important;
        height: 100% !important;
        overflow-x: auto;
      }

      .view-table {
        @include flex-column;

        text-align: left;
        padding: 15px;
        max-width: 33%;
        min-width: 300px;

        .view-table-title {
          padding: 10px 10px;
          border-bottom: 0px solid darken($app-view-form-color, 25%);
          font-size: 17px;
          font-weight: 800;
        }

        .view-table-content {
          @include flex-column;
          box-shadow: rgb(0 0 0 / 20%) -4px 4px 5px 0px;

          & > div {
            flex: 1;

            &.flex-0 {
              flex: 0 !important;
            }
          }
        }

        .view-table-row {
          padding: 5px 10px;
          font-size: 14px;
          flex: 1;

          &:nth-child(even){
            background: darken($app-view-form-color, 10%);
          }

          &:nth-child(odd){
            background: darken($app-view-form-color, 5%);
          }

          &:hover:not(.selected) {
            background: darken($app-view-form-color, 20%);
            cursor: pointer;
          }
        }

        .view-table-title, .view-table-row {
          @include flex-row;

          & > div {
            flex: 1;

            &.flex-0 {
              flex: 0 !important;
            }
          }
        }

        .view-table-column {
          @include flex-column;
        }
      }
    }

    [tool-tip]{
      &:hover {
        .tool-tip {
          @include flex-column;
          display: flex !important;

          &:hover {
            @include flex-column;
            display: flex !important;
          }
        }
      }

      .tool-tip {
        position: absolute;
        display: none !important;
        padding: 20px;
        
        .tool-tip-content {
          border-radius: 4px;
          position: relative;
          padding: 5px 10px;
          background: darken($app-view-form-color, 65%);
          box-shadow: 0 5px 7px 0px rgba(0, 0, 0, 0.5);
          color: #fefefe;
          white-space: pre;

          small {
            opacity: 0.75;
          }
        }

        &.up {
          bottom: 100%;
        }

        &.right {
          left: calc(100% + 0px);
          top: -45px;

          .tool-tip-content::after {
            content: "";
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            background: transparent !important;
            top: 25px;
            right: 100%;
            border: 10px solid transparent;
            border-right-color: darken($app-view-form-color, 65%);
          }
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style-type: none;
          }
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
      }
    }

    span.info-icon {
      position: relative;
      font-size: 18px;
      cursor: help;

      .tool-tip {
        font-size: 12px;
        font-weight: 400;
        top: -15px !important;
        padding: 10px !important;

        .tool-tip-content {
          z-index: 2;
          padding: 5px;

          &::after {
            top: 10px !important;
            right: calc(100% - 0px) !important;
            border-width: 6px !important;
            z-index: 0;
          }   
        }
      }
    }
  }
}
</style>
