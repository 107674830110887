<template>
  <div view="create-table" view-modal>
    <div class="view-form create-table">
      <div class="form-title">
        <div class="form-column">
          <h4><i class="las la-list"></i> Create Table</h4>
        </div>
        <div class="form-column deploy">
          <button class="hollow red-hollow cancel" @click="closeModal">Cancel <i class="las la-times-circle"></i></button>
          <button class="hollow" @click="deploy">Deploy <i class="las la-arrow-circle-right"></i></button>
        </div>
      </div>
      <div class="form-body">
        <hr style="margin-top: 0px;">
        <p class="tip">This table will be available for use in storing, editing, viewing, and transforming your custom business information and data.</p>
        <div class="form-row">
          <div class="form-field">
            <label>Table Name</label>
            <input v-model="table_name"/>
          </div>
          <div class="form-field">
            <label>Table Slug <small>(Read-only)</small></label>
            <input v-model="table_slug" readonly/>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <p class="tip">A primary key field will be automatically generated for this table using the format "{table_slug}_id"</p>
        </div>
        <div v-for="(field, index) in table_fields" :key="index" class="form-row new-table-field">
          <div v-if="index == 0" class="form-field">
            <button class="icon" @click="addField"><i class="las la-plus"></i></button>
          </div>
          <div v-else>
            <button class="icon red" @click="removeField(index)"><i class="las la-ban"></i></button>
          </div>
          <div class="form-field">
            <select v-model="field.type">
              <option value="string">String</option>
              <option value="int">Integer</option>
              <option value="float">Float</option>
              <option value="boolean">Boolean</option>
            </select>
          </div>
          <div class="form-field">
            <input v-model="field.slug" placeholder="Field Slug (ex: user_id)"/>
          </div>
          <div class="form-field">
            <input v-model="field.value" placeholder="Default Value (ex: 1)"/>
          </div>
          <div class="form-field checkbox">
            <input type="checkbox" v-model="field.is_title_field"/>
            <label class="checkbox-label"><small>Title Field</small></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      table_name: '',
      table_slug: '',
      table_fields: [
        { type: 'string', slug: '', value: '', is_title_field: ''}
      ]
    };
  },
  watch: {
    // Watch the first input and update the second input with a formatted version
    table_name(name) {
      this.table_slug = this.tableSlug(name);
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['login']),
    tableSlug(name) {
      return name.split(' ').join('_').toLowerCase(); // Transform the input to uppercase
    },
    addField(){
      this.table_fields.push({ type: 'string', slug: '', value: '', is_title_field: ''})
    },
    removeField(index){
      if(this.table_fields.length > 1){
        this.table_fields.splice(index, 1);
      }
    },
    async deploy(){
      try {
        const payload = {
          table_name: this.table_name,
          table_slug: this.table_slug,
          table_fields: this.table_fields,
          user: this.user
        };

        const response = await fetch(process.env.VUE_APP_API_URL+'/app/controllers/table_controller.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: `controller=table&method=create&params=`+JSON.stringify(payload)
        });

        const result = await response.json();

        if (result.status === 'success') {
          this.$router.push('/ontology');
          this.closeModal(); 
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.log("failed table deploy - error: "+error.message);
      }
    },
    closeModal(){
      this.$emit('close-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
  .new-table-field {
    margin-bottom: 10px;
  }

  .deploy {
    margin-left: auto;
    margin-right: 15px;
    
    button.cancel {
      margin-right: 15px;
    }
  }

</style>
