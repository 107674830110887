<template>
  <div v-if="visible" id="modal" class="modal-overlay">
    <div class="modal-content">
      <slot></slot><!-- Slot for dynamic content -->
      <button id="closeModalButton" class="icon transparent" @click="close">Click here to close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
  },
}
</script>

<style scoped>
#closeModalButton {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0.35;

  &:hover {
    opacity: 0.6;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
